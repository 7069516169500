@mixin cardbox {
  background-color: white;
  border: 3px solid $color-brand-20;
  border-radius: 4px;
  padding: $padSmall $gutter;
}

@mixin cardbox-dark {
  background-color: white;
  border: 3px solid $color-brand;
  border-radius: 4px;
  padding: $padSmall $gutter;
}

